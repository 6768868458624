import React, { useEffect } from "react";
import MindBodyWidget from "../../components/mindbody";
import TeamImg from "../../images/team.jpeg";
import Layout from "../../components/layout-default";
import Breadcrumbs from "../../components/breadcrumbs";
import ErrorBoundary from "../../components/ErrorBoundary";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Classes",
    link: "classes",
  },
  {
    label: "Series",
    link: "classes/series",
  },
];

export default () => {
  useEffect(() => {
    const timer = setInterval(() => {
      var mbScript =
        typeof document !== `undefined`
          ? document.querySelector(
            `[data-link-class=healcode-contract-text-link] .healcode-link`
          )
          : undefined;
      console.log("trying..");
      if (mbScript) {
        clearInterval(timer);
        const wrapper =
          typeof document !== `undefined`
            ? document.querySelector(".contract-wrapper")
            : undefined;
        wrapper.classList.add("is-active");
      }
    }, 1000);
  });

  return (
    <Layout
      title="Bachata &amp; Salsa Series Schedule | RF Dance"
      description="Sign up for a bachata or salsa series with RF Dance online."
      pathname="classes/series"
      img={TeamImg}
      noHero="no-hero"
    >
      <div className="container" style={{ minHeight: `50vh` }}>
        <Breadcrumbs crumbs={crumbs} />
        <p
          style={{
            position: `absolute`,
            margin: `4em auto`,
            zIndex: -1,
            left: `50%`,
            marginLeft: `-70px`,
          }}
        >
          Loading series...
        </p>
        <ErrorBoundary>
          <MindBodyWidget
            id="mb-script-02"
            type="schedules"
            widgetPartner="object"
            widgetId="d419202709b0"
            widgetVersion="1"
          />
        </ErrorBoundary>
      </div>
      <style jsx="jsx">{`
        .contract-wrapper {
          text-align: center;
          padding: 3em 0;
          background-color: #fff;
          display: none;
        }
        .contract-wrapper a {
          background-color: #209cee;
          padding: 1em 2em;
          border-radius: 27px;
          color: #fff;
          border: 1px solid #209cee;
        }
        .contract-wrapper a:hover {
          background-color: #fff;
          color: #209cee;
        }
        .contract-header,
        .contract-body {
          display: none;
        }
        .contract-wrapper.is-active {
          display: block;
        }
        .contract-wrapper.is-active .contract-header,
        .contract-wrapper.is-active .contract-body {
          display: block;
        }
        .contract-body {
          max-width: 80%;
          margin: 0 auto 3em;
        }
      `}</style>
    </Layout>
  );
};
